<template>
	<div class="row">
		<div class="col-12">
			<b>{{ $t('employeeConditions.contributionGroup') }}</b>
		</div>
		<div class="col-12">
			<select required v-model="employeeCondition.group" class="form-control" :disabled="disabled">
				<option v-for="(obj, index) in groups" :key="obj.id" :value="obj.id" :selected="employeeCondition.group == index">
					{{ obj.name }}
				</option>
			</select>
		</div>
		<div class="col-12">
			<label>
				<b>Stilling</b>
			</label>
			<InputText v-model="employeeCondition.position" :place="'Stilling'" :required="true" />
		</div>
		<div class="col-12">
			<label>
				<b>Grundløn pr mnd</b>
			</label>
			<InputNumber v-model.number="employeeCondition.salary" :required="true"> </InputNumber>
		</div>
		<div class="col-12">
			<label>
				<b>Tillægsløn pr mnd</b>
			</label>
			<InputNumber v-model.number="employeeCondition.supplementarySalary" :required="true"> </InputNumber>
		</div>
		<div class="col-12">
			<label>
				<b>Ansættelsedato</b>
			</label>
			<InputDate v-model="employeeCondition.employmentDate" :required="true"> </InputDate>
		</div>
		<div class="col-12">
			<label>
				<b>Pension startdato</b>
			</label>
			<InputDate v-model="employeeCondition.pensionStartDate" :required="pensionIsMandatory"> </InputDate>
		</div>
		<div class="col-12">
			<label>
				<b>Sundhed startdato</b>
			</label>
			<InputDate v-model="employeeCondition.healthInsuranceStartDate" :required="healthInsuranceIsMandatory"> </InputDate>
		</div>
		<div class="col-12">
			<label>
				<b>Kommentar ved tiltrædelse</b>
			</label>
			<InputTextArea v-model="employeePolicy.companyAdminComment"></InputTextArea>
		</div>
	</div>
</template>

<script>
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputTextArea from '@/modules/global/atomComponents/InputTextArea.vue'

export default {
	name: 'EmployeeInfo',
	mounted() {
		console.log('EMPLOYEE INFO LOADED')
	},
	props: {
		value: Object,
		disabled: Boolean,
		groups: Array,
	},
	components: {
		InputText,
		InputNumber,
		InputDate,
		InputTextArea,
	},
	computed: {
		employeePolicy: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		employeeCondition: {
			get() {
				return this.employeePolicy.employeeCondition
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		companyInsurances() {
			return this.$store.getters['companyVuex/insurances']
		},
		companyPensions() {
			return this.$store.getters['companyVuex/pensions']
		},
		healthInsuranceIsMandatory(){
			var selectedGroupId = this.employeeCondition?.group
			var companyInsurance = this.companyInsurances?.find((insurance) => insurance.groups.includes(selectedGroupId))
			return companyInsurance?.insurance?.healthInsuranceIsMandatory
		},
		pensionIsMandatory(){
			var selectedGroupId = this.employeeCondition?.group
			var companyPension = this.companyPensions?.find((pension) => pension.groups.includes(selectedGroupId))
			return companyPension?.pension?.pensionIsMandatory
			
		}
	},
}
</script>

<style></style>
