<template>
	<div class="row">
		<div class="col-12">
			<label
				><b>{{ $t('global.user.cpr') }}</b></label
			>
			<InputText v-model="employeeInfo.cpr" :place="$t('employeeInfo.cpr')" :required="true" @input="lookupCpr" />
		</div>
		
		<div class="col-12">
			<label>
				<b>{{ $t('global.user.firstName') }}</b>
			</label>
			<InputText v-model="employeeInfo.firstName" :place="$t('employeeInfo.name')" :required="true" />
		</div>
		<div class="col-12">
			<label>
				<b>{{ $t('global.user.lastName') }}</b>
			</label>
			<InputText v-model="employeeInfo.lastName" :place="$t('employeeInfo.lastName')" :required="true" />
		</div>
		
		<div class="col-12">
			<label>
				<b>{{ $t('global.user.email') }}</b>
			</label>
			<InputText v-model="employeeInfo.email" :place="$t('employeeInfo.emailPrimary')" :required="true" />
		</div>

		<div class="col-12">
			<label>
				<b>{{ $t('global.user.emailSecondary') }}</b>
			</label>
			<InputText v-model="employeeInfo.emailSecondary" :place="$t('employeeInfo.emailSecondary')" :disabled="disabled" />
		</div>

		<div class="col-12">
			<label>
				<b>{{ $t('global.user.phone') }}</b>
			</label>
			<InputPhone v-model="employeeInfo.phone" :place="$t('employeeInfo.phone')" :disabled="disabled" />
		</div>
		<div class="col-12">
			<label>
				<b>{{ $t('global.user.phoneSecondary') }}</b>
			</label>
			<InputPhone v-model="employeeInfo.phoneSecondary" :place="$t('employeeInfo.phone')" :disabled="disabled" />
		</div>
	</div>
</template>

<script>
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputPhone from '@/modules/global/atomComponents/InputPhone.vue'

export default {
	name: 'EmployeeInfo',
	mounted() {
		console.log('EMPLOYEE INFO LOADED')
	},
	props: {
		value: Object,
		disabled: Boolean,
	},
	components: {
		InputText,
		InputPhone
	},
	computed: {
		employeeInfo: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
	methods: {
		lookupCpr() {
			if (this.employeeInfo.cpr.length == 10) {
				console.log("LOOKUP CPR")
				this.$emit('lookupCpr', this.employeeInfo.cpr)
			}
		},
	},
}
</script>

<style></style>
